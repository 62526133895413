import Vue from 'vue'
import App from './App.vue'
import 'element-ui/lib/theme-chalk/index.css'
import 'normalize.css/normalize.css' 
import store from './store/index'
import api from "@/utils/request"
Vue.prototype.$api= api
Vue.config.productionTip = false

import ElementUI from 'element-ui'
Vue.use(ElementUI)

import router from './router/router'
new Vue({
  render: h => h(App),
  router,
  store,
  beforeCreate(){
    Vue.prototype.$bus = this //安装 全局事件总线 this指向vue原型
  }
}).$mount('#app')
