<template>
    <div class="header"> 
        <!-- <image src="@/assets/logo.png" ></image> -->
        <div class="left-menu">
            <span @click="menuChange">
                <i v-show="isClickMenu" style="font-size:20px;color:#fff" class="el-icon-s-fold"></i>
                <i v-show="!isClickMenu" style="font-size:20px;color:#fff" class="el-icon-s-unfold"></i>
            </span>&nbsp;
        </div>
        <div class="right-menu">
        <el-dropdown class="avatar-container" trigger="click">
            <div class="user">
              <i class="el-icon-s-custom" style="color: #fff; font-size: 20px;"></i>
<!--                <img :src="'https://images.maxdata.cc/5a/'+ shopInfo.avatar" width="30" height="30" style="border-radius: 50%"/>&nbsp;-->
<!--                <span style="color:#fff">{{shopInfo.name}}</span>-->
            </div>
            <el-dropdown-menu slot="dropdown" class="user-dropdown">
            <router-link to="/setup">
                <el-dropdown-item>
                设置
                </el-dropdown-item>
            </router-link>
            <el-dropdown-item divided @click.native="logout">
                <span style="display:block;">退出登录</span>
            </el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
        </div>
    </div>
</template>

<script>
import {removeToken} from '@/utils/auth'
export default {
    data(){
        return{
            isClickMenu:true,
            shopInfo: {},
        }
    },
    mounted() {
      this.shopInfo = JSON.parse(localStorage.getItem('userInfo'))
    },
  methods:{
        menuChange(){
            this.isClickMenu = !this.isClickMenu
            this.$bus.$emit('menu',this.isClickMenu)
        },
       
        logout(){
            removeToken()
            this.$router.push('/login')
        }
    }
}
</script>

<style lang="scss" scoped>
.header{
    height: 60px;
    background-color: #00bcd4;
    display: flex;
    justify-content:space-between;
    align-items:center;
    padding: 0 20px;
    
}
.user>* {
        vertical-align: middle;
        cursor:pointer;
    }

</style>