<template>
  <div>
    <el-container>
        <el-aside :width="asideWidth">
            <asidebar :isMenu="isMenu"></asidebar>
        </el-aside>
        <el-container>
            <el-header>
                <navbar></navbar>
            </el-header>
            <el-main>
                <router-view></router-view>
            </el-main>
        </el-container>
    </el-container>
  </div>
</template>

<script>
import navbar from './component/bavBar.vue'
import asidebar from './component/asideBar.vue'
export default {
    name:'layout',
    data(){
        return{
            asideWidth:'200px',
            isMenu:true
        }
    },
    components:{
        navbar,
        asidebar
    },
    mounted(){
        this.$bus.$on('menu',(data)=>{
            if(data){
                this.asideWidth='200px'
                this.isMenu=true
            }else{
                this.asideWidth='60px'
                this.isMenu=false
            }
        })
    }
}
</script>

<style lang="scss" scoped>
.el-header{
    padding: 0;
}
.container{
    height: 100vh;
}
.el-aside{
    height: 100vh;
    transition: all 0.3s;
    overflow:hidden;
}
.el-main{
    background-color:rgb(251, 248, 248)
}
</style>