<template>
   <div class="left-aside">
      <!-- <el-aside width="150px"> -->
            <div class="title"  >
                <span v-show="isMenu" style="color:#fff;font-size:20px">商户平台</span>
                <span v-show="!isMenu" style="color:#fff;font-weight: bold;">商</span>
            </div>
            <div class="meum">菜单</div>
            <el-menu
                :default-active='num'
                class="el-menu-vertical-demo"
                >
                <router-link to="/admin">
                    <el-menu-item index="1" >
                            <i class="el-icon-s-home icon"></i>
                            <span v-show="isMenu">首页</span>
                    </el-menu-item>
                </router-link>

                <router-link to="/category">
                    <el-menu-item index="2">
                            <i class="el-icon-s-grid icon"></i>
                            <span v-show="isMenu">商品分类</span>
                    </el-menu-item>
                </router-link>

                <router-link to="/goods">
                    <el-menu-item index="3" >
                            <i class="el-icon-s-shop icon"></i>
                            <span v-show="isMenu">商品管理</span>
                    </el-menu-item>
                </router-link>

                <router-link to="/goods_order">
                    <el-menu-item index="4" >
                            <i class="el-icon-s-order icon"></i>
                            <span v-show="isMenu">商品订单</span>
                    </el-menu-item>
                </router-link>

                <router-link to="/express">
                    <el-menu-item index="5" >
                            <i class="el-icon-s-cooperation icon"></i>
                            <span v-show="isMenu">快递管理</span>
                    </el-menu-item>
                </router-link>

                <router-link to="/goods_refund">
                    <el-menu-item index="6" >
                        <i class="el-icon-s-release icon"></i>
                        <span v-show="isMenu">退款申请</span>
                    </el-menu-item>
                </router-link>

                <router-link to="/goods_refund_info">
                    <el-menu-item index="7" >
                        <i class="el-icon-s-management icon"></i>
                        <span v-show="isMenu">退款记录</span>
                    </el-menu-item>
                </router-link>

                <router-link to="/goods_appraise">
                    <el-menu-item index="8" >
                        <i class="el-icon-s-claim icon"></i>
                        <span v-show="isMenu">商品评价</span>
                    </el-menu-item>
                </router-link>
                <el-submenu index="10">
                    <template slot="title">
                        <i class="el-icon-s-tools"></i>
                        <span slot="title" v-show="isMenu">订单管理</span>
                    </template>
                    <el-menu-item-group>
                        <router-link to="/order_statistics">
                    <el-menu-item index="10-1" >
                        <i class="el-icon-s-data icon"></i>
                        <span v-show="isMenu">订单统计</span>
                    </el-menu-item>
                </router-link>

                <router-link to="/sales_statistics">
                    <el-menu-item index="10-2">
                        <i class="el-icon-menu icon"></i>
                        <span v-show="isMenu">销量统计</span>
                    </el-menu-item>
                </router-link>

                <router-link to="/revenue_statistics">
                    <el-menu-item index="10-3" >
                        <i class="el-icon-s-grid icon"></i>
                        <span v-show="isMenu">营收统计</span>
                    </el-menu-item>
                </router-link>
                    </el-menu-item-group>
                </el-submenu>
                
                <el-submenu index="12">
                    <template slot="title">
                        <i class="el-icon-s-tools icon"></i>
                        <span slot="title" v-show="isMenu">配置中心</span>
                    </template>
                    <el-menu-item-group>
                      <router-link to="/setting_audit">
                        <el-menu-item index="12-1">店铺设置</el-menu-item>
                      </router-link>
                      <!-- <router-link to="/photo">
                        <el-menu-item index="12-2">店铺相册</el-menu-item>
                      </router-link> -->
                    </el-menu-item-group>
                </el-submenu>
              <router-link to="/message">
                <el-menu-item index="13" >
                  <i class="el-icon-s-comment icon"></i>
                  <span v-show="isMenu">消息通知</span>
                </el-menu-item>
              </router-link>
              <el-submenu index="18">
                 <template slot="title">
                   <i class="el-icon-s-tools"></i>
                   <span slot="title" v-show="isMenu">用户日志</span>
                 </template>
                 <el-menu-item-group>
                   <router-link to="/record/login">
                     <el-menu-item index="8-1" v-show="isMenu">登录日志</el-menu-item>
                   </router-link>
                   <router-link to="/record/operate">
                     <el-menu-item index="18-2" v-show="isMenu">操作日志</el-menu-item>
                   </router-link>
                 </el-menu-item-group>
               </el-submenu>
            </el-menu>
        <!-- </el-aside> -->
  </div>
</template>

<script>
export default {
    props:['isMenu'],
    data(){
        return { 
            num:'1'
        }
    },
    mounted(){
       this.locationChange()
    },
    methods:{
        locationChange(){
            switch(location.pathname){
            case '/admin':
            this.num='1'
            break;
            case '/category':
            this.num='2'
            break;
            case '/goods':
            this.num='3'
            break;
            case '/goods_order':
            this.num='4'
            break;
             case '/express':
            this.num='5'
            break;
            case '/goods_refund':
            this.num='6'
            break;
            case '/goods_refund_info':
            this.num='7'
            break;
            case '/goods_appraise':
            this.num='8'
            break;
            case '/order_statistics':
            this.num='9'
            break;
            case '/sales_statistics':
            this.num='10'
            break;
            case '/revenue_statistics':
            this.num='11'
            break;
            case '/setting_audit':
            this.num='12-1'
            break;
            case '/photo':
            this.num='12-2'
            break;
            case '/message':
            this.num='13'
            break;
            
        }
        }
    },
    watch:{
        $route(){
            this.locationChange()
        }
    }
}
</script>

<style scoped lang="scss">
.left-aside{
    height: 100%;
    border-right: 1px solid rgb(232, 230, 230);
}
.title{
    text-align: center;
    height:60px;
    line-height: 60px;
    background-color:#00bcd4;
}
.el-menu-item:hover{
    outline: 0 !important;
    background-color:#00bcd4 !important;
    color:white !important
}
.el-menu-item:hover .icon{
    color:white !important;
}
.el-menu{
    border-right:none !important
}
.meum{
    font-size: 12px;
    color: rgb(195, 192, 192);
    padding: 10px 0 0px 20px;
}
.el-menu-item{
  height: 46px !important;
  line-height: 46px !important;
  list-style: none;
}

</style>