import axios from 'axios'
import { getToken,removeToken} from '@/utils/auth'
import { Loading } from 'element-ui';
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import router from '../router/router';
NProgress.configure({ showSpinner: false }); 

const service = axios.create({
    baseUrl: 'https://afstore.maxdata.cc/api/ecmall/',
    timeout: 5000,
})
let loadingInstance
service.interceptors.request.use(config=>{
    NProgress.start(); 
    loadingInstance = Loading.service({ fullscreen: true ,text:"加载中，请稍后...",spinner:"el-icon-loading",background:"rgba(0, 0, 0, 0.8)"});
    if(getToken()){
        config.headers['Authorization'] = ' Bearer '+ getToken();
    }
    config.headers['Content-Type']='application/json;charset=UTF-8'
    return config
},error=>{
    return promise.reject(error)
})
service.interceptors.response.use(response=>{
    NProgress.done(); 
    loadingInstance.close();
    
    return response
},error=>{
    // alert('服务器数据响应失败'+error)
    console.log(error);
    if(error.response.status==401){
        removeToken()
        router.replace({
            path:'/login'
        })
        loadingInstance.close();
    }
})

export default service