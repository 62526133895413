import Vue from 'vue'
import Router from 'vue-router'
import layout from '@/views/layout/index'
import { getToken } from "@/utils/auth"
Vue.use(Router)
// import nprogress from 'nprogress'
// import 'nprogress/nprogress.css'

 const constantRouter = [
    { 
        path: '/login',
        name:'login',
        component:()=>import('@/views/login/login'),
        meta:{}
    },
    { 
        path:'/',
        name:'layout',
        component:layout,
        redirect: '/admin',
        meta:{},
        children:[
            {
                path:'admin',
                name:'admin',
                component:()=>import('@/views/layout/component/admin')
            },
            {
                path:'category',
                component:()=>import('@/views/layout/component/category')
            },
            {
                path:'newproduct',
                component:()=>import('@/views/layout/component/newproduct')
            },
            {
                path:'newlybuild',
                component:()=>import('@/views/layout/component/newlybuild')
            },
           
            {
                path:'goods',
                component:()=>import('@/views/layout/component/goods')
            },
            {
                path:'goods_order',
                component:()=>import('@/views/layout/component/goods_order')
            },
            {
                path:'express',
                component:()=>import('@/views/layout/component/express')
            },
            {
                path:'/express_editor',
                component:()=>import('@/views/layout/component//express_editor')
            },
            {
                path:'goods_refund',
                component:()=>import('@/views/layout/component/goods_refund')
            },
            {
                path:'recycle_bin',
                component:()=>import('@/views/layout/component/recycle_bin')
            },
            {
                path:'order_statistics',
                component:()=>import('@/views/layout/component/order_statistics')
            },
            {
                path:'sales_statistics',
                component:()=>import('@/views/layout/component/sales_statistics')
            },
            {
                path:'revenue_statistics',
                component:()=>import('@/views/layout/component/revenue_statistics')
            },
            {
                path:'goods_refund_info',
                component:()=>import('@/views/layout/component/goods_refund_info')
            },
            {
                path:'goods_appraise',
                component:()=>import('@/views/layout/component/goods_appraise')
            },
            {
                path:'setting_audit',
                component:()=>import('@/views/layout/component/setting_audit')
            },
            {
                path:'photo',
                component:()=>import('@/views/layout/component/photo')
            },
            {
                path:'message',
                component:()=>import('@/views/layout/component/message')
            },
            {
                path: 'goods_order_deliver',
                component: () => import('@/views/layout/component/goods_order_deliver')
            },
            {
                path:'edit',
                name:'Edit',
                component:()=>import('@/views/layout/component/edit')
            },
            {
                path:'productedit',
                name:'productedit',
                component:()=>import('@/views/layout/component/productedit')
            },
            {
                path:'configure',
                component:()=>import('@/views/layout/component/configure')
            },
            {
                path:'goods_refund_audit',
                component:()=>import('@/views/layout/component/goods_refund_audit')
            },
            {
                path:'goods_appraise_audit',
                name:'goods_appraise_audit',
                component:()=>import('@/views/layout/component/goods_appraise_audit')
            },
            {
                path:'newalbum',
                component:()=>import('@/views/layout/component/newalbum')
            },
            {
                path:'editalbum',
                name:'Editalbum',
                component:()=>import('@/views/layout/component/editalbum')
            },
            {
                path:'setup',
                name:'setup',
                component:()=>import('@/views/layout/component/setup')
            },
            {
                path:'record/login',
                name:'record/login',
                component:()=>import('@/views/layout/record/login')
            },
            {
                path:'record/operate',
                name:'record/operate',
                component:()=>import('@/views/layout/record/operate')
            },
            
        ]
    }
   
]
const createRouter = () => {
    return  new Router({
        scrollBehavior: () => ({ y: 0 }),
        routes: constantRouter,
        mode:'history'
        })
    }
const router = createRouter()
router.beforeEach((to,from,next)=>{
    // nprogress.start()
    if(getToken()){
        if(to.path=='/login'){
            next('/');
        }else{
            next();
        }
    }else{
        if(to.path !=='/login'){
            next('/login');
        }else{
            next();
        }

    }
})
router.afterEach((to,from,next)=>{
    // nprogress.done()
})
export default router