<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
 
}
</script>

<style lang="scss">
 a{
    text-decoration:none
  }
  .el-input__inner{
    border-top: 0px solid #DCDFE6 !important;
    border-right: 0px solid #DCDFE6 !important;
    border-left: 0px solid #DCDFE6 !important;
    border-radius: 0 !important;
  }
  .el-input-number__decrease,.el-input-number__increase,.el-input-group__prepend{
    border-right:none !important;
    background: white !important; 
    border-left:none !important;
    border: none !important;
  }
 .el-submenu__title {
   height: 46px !important;
   line-height: 46px !important;
 }
 .el-upload--picture-card{
    width: 50px !important;
    height: 50px !important;
    line-height: 0px !important;
}
[class^=el-icon-]{
  line-height: 1.7 !important;
}
.el-upload-list--picture-card .el-upload-list__item-actions ,.el-upload-list--picture-card .el-upload-list__item-thumbnail 
 ,.el-upload-list--picture-card .el-upload-list__item{
    width: 50px !important;
    height: 50px !important;
}
 .el-breadcrumb {
   line-height: 2 !important;
 }
 .el-table {
  ::v-deep .el-table__body-wrapper {
    overflow: hidden;
  }
  ::v-deep .el-table__fixed {
    height: 100% !important;
    width: 100% !important;
    overflow-x: scroll;
  }
  ::v-deep .el-table__fixed-right {
    z-index: 4;
  }
}
</style>
